<template functional>
 <!-- eslint-disable -->
<svg xmlns="http://www.w3.org/2000/svg"
  :width="props.size"
  :height="props.size"
  viewBox="0 0 48 48"
  class="msi-spinner">
      <g :fill="props.color" transform="translate(24 24)">
          <rect y="-6.5" x="-6" height="6" width="2"/>
          <rect y="-6.5" x="4" height="6" width="2"/>
          <path d="M-1.906 2.172h4.148v2.234c.008 2.664-4.144 2.609-4.148 0z"/>
          <path d="M-8.889-12.357c-2.55 2.831-5.082 7.76-5.082 12.08s2.54 9.235 5.09 12.047l18.002.018c2.55-2.83 5.096-7.819 5.096-12.139 0-4.32-2.548-9.153-5.098-11.984l-18.008-.021zm2.043 2.844l13.926.016c1.972 2.164 3.944 5.86 3.944 9.162 0 3.302-1.97 7.115-3.942 9.28L-6.84 8.93c-1.973-2.15-3.935-5.907-3.935-9.21 0-3.301 1.957-7.07 3.93-9.234z"/>
          <rect class="ray ray1" transform="rotate(22.5)" y="17" x="-1.4" height="7" width="2.8"/>
          <rect class="ray ray2" transform="rotate(67.5)" y="17" x="-1.4" height="7" width="2.8"/>
          <rect class="ray ray3" transform="rotate(112.5)" y="17" x="-1.4" height="7" width="2.8"/>
          <rect class="ray ray4" transform="rotate(157.5)" y="17" x="-1.4" height="7" width="2.8"/>
          <rect class="ray ray5" transform="rotate(202.5)" y="17" x="-1.4" height="7" width="2.8"/>
          <rect class="ray ray6" transform="rotate(247.5)" y="17" x="-1.4" height="7" width="2.8"/>
          <rect class="ray ray7" transform="rotate(292.5)" y="17" x="-1.4" height="7" width="2.8"/>
          <rect class="ray ray8" transform="rotate(337.5)" y="17" x="-1.4" height="7" width="2.8"/>
        </g>
  </svg>
</template>

<script>
export default {
  name: 'MsiSpinner',
  props: {
    size: {
      type: Number,
      default: 48
    },
    color: {
      type: String,
      default: '#F47216'
    }
  }
};
</script>

<style scoped>
@keyframes msi-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes msi-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.msi-spinner .ray {
  -webkit-animation: msi-spinner linear 1s infinite;
  animation: msi-spinner linear 1s infinite;
}

.msi-spinner .ray1 {
  -webkit-animation-delay: -0.8888888888888888s;
  animation-delay: -0.8888888888888888s;
}
.msi-spinner .ray2 {
  -webkit-animation-delay: -0.7777777777777778s;
  animation-delay: -0.7777777777777778s;
}
.msi-spinner .ray3 {
  -webkit-animation-delay: -0.6666666666666667s;
  animation-delay: -0.6666666666666667s;
}
.msi-spinner .ray4 {
  -webkit-animation-delay: -0.5555555555555556s;
  animation-delay: -0.5555555555555556s;
}
.msi-spinner .ray5 {
  -webkit-animation-delay: -0.4444444444444444s;
  animation-delay: -0.4444444444444444s;
}
.msi-spinner .ray6 {
  -webkit-animation-delay: -0.33333333333333337s;
  animation-delay: -0.33333333333333337s;
}
.msi-spinner .ray7 {
  -webkit-animation-delay: -0.22222222222222232s;
  animation-delay: -0.22222222222222232s;
}

.msi-spinner .ray8 {
  -webkit-animation-delay: -0.11111111111111116s;
  animation-delay: -0.11111111111111116s;
}
</style>