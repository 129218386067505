<template>
  <div class="flex-grow-1 d-flex flex-column p-2">
    <CCard class="download-card p-4 mx-auto mb-0 mt-5">
      <div class="download-title mb-4">DAQ Data Download</div>

      <div class="mb-4">
        You can download a site's DAQ data using this form. You will be notified by email upon the request's completion. The email will contain the links to download the generated data files.
      </div>

      <div class="mb-3">
        <div class="pb-2">Site</div>
        <Multiselect
          v-model="site"
          :options="sites"
          track-by="code"
          label="name"
          placeholder="Select Site"
          selectLabel=""
          deselectLabel=""
          :disabled="requestInProgress"
        />
      </div>
      
      <div class="mb-3">
        <div class="pb-2">Start Date</div>
        <flat-pickr
          v-if="dateConfig"
          :value="startDate"
          :config="dateConfig"
          placeholder="Start Date"
          :disabled="requestInProgress"
          @input="handleStartDateInput" />
      </div>

      <div class="mb-3">
        <div class="pb-2">End Date</div>
        <flat-pickr
          v-if="dateConfig"
          :value="endDate"
          :config="dateConfig"
          placeholder="End Date"
          :disabled="requestInProgress"
          @input="handleEndDateInput" />
      </div>

      <div class="mb-3">
        <div class="pb-2">IV Data Frequency</div>
        <Multiselect
          v-model="ivFreq"
          :options="ivFreqOptions"
          track-by="name"
          label="name"
          placeholder="Select IV Data Frequency"
          selectLabel=""
          deselectLabel=""
          :disabled="requestInProgress"
        />
        <div class="pt-2 text-muted">
          <div>The frequency of IV data points to download the data in.</div>
          <div><b>15 minutes</b>: data frequency is every 15 minutes.</div>
          <div><b>Original</b>: data frequency is the same as the IV collection frequency.</div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <CButton
          class="mr-3"
          color="primary"
          :disabled="downloadDisabled"
          @click="handleDownload">
          Download
        </CButton>

        <msi-spinner v-if="requestInProgress" :size="28" />
      </div>
    </CCard>

    <CToaster
      placement="top-end"
      :autohide="5000">
      <CToast
        v-for="(toast, index) in toasts"
        :key="index"
        :color="toast.color"
        :show.sync="toast.show"
        :header="toast.title">
        {{ toast.content }} 
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import {
  CCard,
  CButton,
  CToaster,
  CToast
} from '@coreui/vue';

import flatPickr from 'vue-flatpickr-component';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { DateTime } from 'luxon';
import Multiselect from 'vue-multiselect';

import MsiSpinner from '../components/MsiSpinner.vue';

export default {
  name: 'Home',
  components: {
    CCard,
    CButton,
    CToaster,
    CToast,
    flatPickr,
    MsiSpinner,
    Multiselect
  },
  data() {
    this.$options.monthSelector = new MonthSelectPlugin({ dateFormat: 'Y-m-d', altFormat: 'M Y' });

    return {
      site: null,
      sites: [],
      startDate: null,
      endDate: null,
      ivFreq: null,
      ivFreqOptions: [{ name: '15 minutes', value: '15min' }, { name: 'Original', value: 'original' }],
      dateConfig: null,
      requestInProgress: false,
      toasts: []
    };
  },
  computed: {
    downloadDisabled() {
      const { site, startDate, endDate, ivFreq, requestInProgress } = this;
      if (!site || !startDate || !endDate || !ivFreq || requestInProgress) return true;
      return false;
    }
  },
  methods: {
    handleStartDateInput(input) {
      this.startDate = DateTime.fromSQL(input, { zone: 'UTC' }).toSQLDate();
    },
    handleEndDateInput(input) {
      this.endDate = DateTime.fromSQL(input, { zone: 'UTC' }).toSQLDate();
    },
    async handleDownload() {
      this.requestInProgress = true;

      let response;
      let err;
      const siteName = this.site.name;
      const siteCode = this.site.code;
      const startDate = DateTime.fromSQL(this.startDate, { zone: 'UTC' });
      const endDate = DateTime.fromSQL(this.endDate, { zone: 'UTC' });
      const ivFreq = this.ivFreq.value;

      try {
        const query = { fromYear: startDate.year, fromMonth: startDate.month, toYear: endDate.year, toMonth: endDate.month, ivFreq };
        response = await this.$api.post(`/${siteCode}`, { query });
      } catch (e) {
        err = e;
        if (e.name === 'ApiError') response = { message: e.message };
      } finally {
        if (response) this.toasts.push({ title: `Data Download For ${siteName}`, content: response.message, color: err ? 'danger' : 'success', show: true  });
        else this.toasts.push({ title: `Data Download For ${siteName}`, content: 'An unexpected error occurred.', color: 'danger', show: true  });
        this.requestInProgress = false;
      }
    }
  },
  async created() {
    this.sites = await this.$daqApi.get('/sites');
  },
  mounted() {
    this.dateConfig = {
      maxDate: DateTime.now().setZone('UTC').toSQLDate(),
      altInput: true,
      altInputClass: 'flatpickr-input',
      plugins: [this.$options.monthSelector],
      disableMobile: true
    };
  }
};
</script>

<style scoped>
.download-card {
  max-width: 500px;
  width: 100%;
}

.download-title {
  font-size: 1.5rem;
  font-weight: 300;
}

::v-deep .flatpickr-input {
  width: 100%;
  min-height: 40px;
  text-align: left;
  padding: 8px 13px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  font-size: 16px;
  color: #35495e;
}

::v-deep .flatpickr-input:disabled {
  cursor: default;
}

::v-deep .flatpickr-input::placeholder {
  font-size: 14px;
  color: #adadad;
}
</style>